/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
// ORI-99466 新增文件
import React from 'react';
import { NO_TIME_RANGE, t } from '@superset-ui/core';
import { Moment } from 'moment';
import { Col, Row } from 'src/components';
import { DatePicker } from 'src/components/DatePicker';
import { InfoTooltipWithTrigger } from '@superset-ui/chart-controls';
import {
  MOMENT_FORMAT,
  dttmToMoment,
  SEPARATOR,
  ISO8601_AND_CONSTANT,
} from 'src/explore/components/controls/DateFilterControl/utils';
import { FrameComponentProps } from 'src/explore/components/controls/DateFilterControl/types';

// const ACTUAL_SEPARATOR = ' ≤ col < ';

function getSpecificRange(actualTimeValue: string): string[] {
  if (!actualTimeValue.includes(SEPARATOR)) {
    return ['', ''];
  }
  let [since, until] = actualTimeValue.split(SEPARATOR);
  if (!ISO8601_AND_CONSTANT.test(since)) {
    since = '';
  }
  if (!ISO8601_AND_CONSTANT.test(until)) {
    until = '';
  }
  return [since, until];
}

function getAdvancedRange(value: string, specificTimeValue: string): string[] {
  if (value.includes(SEPARATOR)) {
    return value.split(SEPARATOR);
  }
  return getSpecificRange(specificTimeValue);
}

export function SpecificFrame(props: FrameComponentProps) {
  const [defaultSince, defaultUntil] = getSpecificRange(
    props.specificTimeValue || '',
  );
  const [since, until] = getAdvancedRange(
    props.value || '',
    props.specificTimeValue || '',
  );
  function onChange(control: 'since' | 'until', value: string) {
    let timeRange = '';
    if (control === 'since') {
      timeRange = `${value}${SEPARATOR}${until}`;
    } else {
      timeRange = `${since}${SEPARATOR}${value}`;
    }
    if (timeRange === SEPARATOR) {
      props.onChange(NO_TIME_RANGE);
    } else {
      props.onChange(timeRange);
    }
  }

  return (
    <div data-test="custom-frame">
      <Row gutter={24}>
        <Col span={12}>
          <div className="control-label">
            {t('START (INCLUSIVE)')}{' '}
            <InfoTooltipWithTrigger
              tooltip={t('Start date included in time range')}
              placement="right"
            />
          </div>
          <Row>
            <DatePicker
              showTime={props.allowSelectTime} // ORI-97327
              format="YYYY-MM-DD HH:mm:ss" // ORI-97327
              defaultValue={
                defaultSince ? dttmToMoment(defaultSince) : undefined
              }
              onChange={(datetime: Moment) =>
                onChange(
                  'since',
                  // ORI-97327 CHANGED
                  datetime
                    ? (props.allowSelectTime
                        ? datetime
                        : datetime.startOf('day')
                      ).format(MOMENT_FORMAT)
                    : '',
                  // ORI-97327 END
                )
              }
              getPopupContainer={trigger =>
                trigger.parentElement as HTMLElement
              }
              className={since ? 'has-value' : ''}
              allowClear={false} // ORI-104991
              inputReadOnly // ORI-105255
            />
          </Row>
        </Col>
        <Col span={12}>
          <div className="control-label">
            {t('END (EXCLUSIVE)')}{' '}
            <InfoTooltipWithTrigger
              tooltip={t('End date excluded from time range')}
              placement="right"
            />
          </div>
          <Row>
            <DatePicker
              showTime={props.allowSelectTime} // ORI-97327
              format="YYYY-MM-DD HH:mm:ss" // ORI-97327
              defaultValue={
                defaultUntil ? dttmToMoment(defaultUntil) : undefined
              }
              onChange={(datetime: Moment) =>
                onChange(
                  'until',
                  // ORI-97327 CHANGED
                  datetime
                    ? (props.allowSelectTime
                        ? datetime
                        : datetime.startOf('day')
                      ).format(MOMENT_FORMAT)
                    : '',
                  // ORI-97327 END
                )
              }
              getPopupContainer={trigger =>
                trigger.parentElement as HTMLElement
              }
              className={until ? 'has-value' : ''}
              allowClear={false} // ORI-104991
              inputReadOnly // ORI-105255
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}
