/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
// ORI-103836 ADD
import React, { FC } from 'react';
import { ControlState } from '@superset-ui/chart-controls';
import { t } from '@superset-ui/core';
import Button, { OnClickHandler } from '../Button';

export type FooterProps = {
  onCancel?: OnClickHandler;
  handleSave?: OnClickHandler;
  onConfirmCancel?: OnClickHandler;
  onDismiss?: OnClickHandler;
  canSave?: Function;
  controls: Record<string, ControlState>;
};

const Footer: FC<FooterProps> = ({
  canSave,
  onCancel,
  handleSave,
  controls,
}) => (
  <>
    <Button
      key="cancel"
      data-test="native-filter-modal-cancel-button"
      onClick={onCancel}
    >
      {t('Cancel')}
    </Button>
    <Button
      disabled={canSave ? !canSave(controls) : false}
      key="submit"
      onClick={handleSave}
      data-test="native-filter-modal-save-button"
    >
      {t('Save')}
    </Button>
  </>
);

export default Footer;
// ORI-103836 END
