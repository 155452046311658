/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React from 'react';
// ORI-97327 CHANGED
import { NO_TIME_RANGE, styled } from '@superset-ui/core';
// ORI-97327 END
import { Radio } from 'src/components/Radio';
import {
  COMMON_RANGE_OPTIONS,
  COMMON_RANGE_SET,
  /* ORI-97327
  DATE_FILTER_TEST_KEY,
  */
} from 'src/explore/components/controls/DateFilterControl/utils';
import {
  CommonRangeType,
  FrameComponentProps,
} from 'src/explore/components/controls/DateFilterControl/types';
import { isComWx, isWx } from 'src/constants'; // ORI-105392

// ORI-97327 ADDED
const StyledRadioGroup = styled(Radio.Group)`
  display: grid;
  grid-template-columns: repeat(${isWx || isComWx ? 2 : 3}, 1fr);
`;
// ORI-97327 END

export function CommonFrame(props: FrameComponentProps) {
  let commonRange = NO_TIME_RANGE; // ORI-97327 CHANGED
  if (COMMON_RANGE_SET.has(props.value as CommonRangeType)) {
    commonRange = props.value;
  } else {
    props.onChange(commonRange);
  }

  return (
    <>
      {/* ORI-97327
      <div
        className="section-title"
        data-test={DATE_FILTER_TEST_KEY.commonFrame}
      >
        {t('Configure Time Range: Last...')}
      </div>
      */}
      {/* ORI-97327 ADDED */}
      <StyledRadioGroup
        value={commonRange}
        onChange={(e: any) => props.onChange(e.target.value)}
      >
        {COMMON_RANGE_OPTIONS.map(({ value, label }) => (
          <Radio key={value} value={value} className="vertical-radio">
            {label}
          </Radio>
        ))}
      </StyledRadioGroup>
      {/* ORI-97327 END */}
    </>
  );
}
