/* eslint-disable no-param-reassign */
/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
// ORI-103836 ADD
import React, { ReactElement, useState } from 'react';
import Popover from 'src/components/Popover';
import { FooterProps } from 'src/components/Footer/Footer';
import { ColumnConfigPopoverProps } from '../ColumnConfigPopover';

export type ColumnConfigItemProps = ColumnConfigPopoverProps & {
  content: any;
  overlayInnerStyle: any;
  children: ReactElement<any, any>;
  footer: FooterProps;
};

export default React.memo(function PopupoverWithFooter({
  column,
  footer,
  content,
  overlayInnerStyle,
  children,
}: ColumnConfigItemProps) {
  const [visible, setVisible] = useState(false);
  const onVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };
  footer.onCancel = () => {
    onVisibleChange(false);
  };
  footer.handleSave = () => {
    onVisibleChange(false);
  };
  return (
    <Popover
      title={column.name}
      visible={visible}
      content={content}
      onVisibleChange={onVisibleChange}
      trigger="click"
      placement="right"
      overlayInnerStyle={overlayInnerStyle}
      overlayClassName="column-config-popover"
    >
      {children}
    </Popover>
  );
});
// ORI-103836 END
